import openAiRequests from "./httpService";
import openAiRequestsIab from "./iabHttpService";
import openAiRequestsCmc from "./cmcHttpService";
import openAiRequestsVoiceAskNissan from "./VoiceAskNissanHttpService";
import requests from "./http";

const ChatService = {
  sendPrompt(body) {
    return requests.post(`chat`, body);
  },

  getSessionFromId(sessionId) {
    return requests.get(`session/${sessionId}`);
  },

  sendHoorayHealthChatbot(body) {
    return openAiRequestsIab.post("/chat/ask", body);
  },

  sendPromptAskYourPDF(body) {
    return openAiRequests.post(
      `/${process.env.REACT_APP_ASK_YOUR_PDF_SLUG}`,
      body
    );
  },
  sendPromptMedichatbot(body) {
    return openAiRequests.post(`/${process.env.REACT_APP_MEDIBOT_SLUG}`, body);
  },
  sendPromptIABchatbot(body) {
    return openAiRequests.post(`/iab_eng`, body);
  },
  sendPromptIABBanglachatbot(body) {
    return openAiRequestsIab.post(`/ask_bangla`, body);
  },
  sendPromptIABchatbotTranslator(body) {
    return openAiRequestsIab.post(`/ask_english`, body);
  },
  sendPromptCMCchatbotTranslator(body) {
    return openAiRequestsCmc.post(`/ask_cmc_chatbot`, body);
  },
  sendPromptVoiceAskNissan(body) {
    return openAiRequestsVoiceAskNissan.post(`/voice_ask_nissan`, body);
  },
};

export default ChatService;
