import {
  Bars3Icon,
  CpuChipIcon,
  PencilSquareIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import InputBox from "../components/InputBox";
import { Link, useOutletContext, useParams } from "react-router-dom";
import ChatService from "../services/ChatServices";
import classnames from "../utilities/classnames";
import toast, { LoaderIcon } from "react-hot-toast";
import Typewriter from "typewriter-effect";
import { useRef, useState, useEffect, useContext } from "react";
import useSWR from "swr";
import { UserContext } from "../contexts/UserContext";
import { fetchEventSource } from "@microsoft/fetch-event-source";

const savedChats = [
  // {
  // 	id: 1,
  // 	saved_chats: {
  // 		name: "Sending whatsapp message",
  // 	},
  // 	date: "Few mins ago",
  // },
];

export default function BotOutlet() {
  let { id } = useParams();

  const [sidebarOpen, setSidebarOpen] = useOutletContext();
  const { dispatch, state } = useContext(UserContext);
  const scrollRef = useRef(null);
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [token, setToken] = useState(null);

  useEffect(() => {
    //generate a random token
    const newToken = Math.random().toString(36).substring(7);
    setToken(newToken);
  }, []);

  const { data, isError, isLoading } = useSWR(() =>
    id ? `chatbot/${id}` : null
  );

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversation]);

  useEffect(() => {
    setConversation([]);

    setTimeout(() => {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "Hi there!!!",
          sender: "bot",
        },
      ]);
    }, 500);

    setTimeout(() => {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "How can I assist you today?",
          sender: "bot",
        },
      ]);
    }, 1000);
  }, []);

  const fetchData = async (input) => {
    try {
      var raw = JSON.stringify({
        question: input,
        token: token,
      });
      setLoading(true);

      let newMessage = "";
      let boldOpen = false;
      let boldOpenByAsteric = false;

      await fetchEventSource(
        `${process.env.REACT_APP_SERVER_BASE_URL}chatbot/chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
          onmessage(event) {
            setLoading(false);
            let chankData = event.data
              .trim()
              .replace(/^data: /, "")
              .replace(/" "/g, "")
              .replace(/^"/, "")
              .replace(/"$/, "")
              .replace(/\\u/g, "")
              .replace(/\\n/g, "<br />");

            if (chankData.trim().includes('\\"')) {
              if (boldOpen) {
                chankData = " </b> ";
                boldOpen = false;
              } else {
                chankData = " <b> ";
                boldOpen = true;
              }
            }
            if (chankData.trim().includes("**")) {
              if (boldOpenByAsteric) {
                chankData = " </b><br /><br /> ";
                boldOpenByAsteric = false;
              } else {
                chankData = " <b> ";
                boldOpenByAsteric = true;
              }
            }
            newMessage += chankData;
            setMessage((prevValue) => `${prevValue}${chankData} `);
          },
          onclose() {
            console.log("Connection closed by the server");
          },
          onerror(err) {
            console.log("There was an error from server", err);

            throw new Error();
          },
        }
      );

      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: newMessage,
          sender: "bot",
        },
      ]);

      setMessage("");
    } catch (error) {
      setConversation((current) => [
        ...current,
        {
          id: current.length + 1,
          content: "Sorry, I am unable to fetch the data",
          sender: "bot",
        },
      ]);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isBlank = input.trim().length === 0;

    if (!isBlank) {
      setConversation((current) => [
        ...current,
        { id: current.length + 1, content: input, sender: "user" },
      ]);

      fetchData(input);
      setInput("");

      // setConversation((current) => [
      //   ...current,
      //   {
      //     id: current.length + 1,
      //     content: result.response,
      //     sender: "bot",
      //   },
      // ]);
    }
  };

  const sendQusFromSampleQustion = async (qus) => {
    setConversation((current) => [
      ...current,
      { id: current.length + 1, content: qus, sender: "user" },
    ]);
    fetchData(qus);
    setInput("");
  };

  const getSessionFromId = async (id) => {
    try {
      const result = await ChatService.getSessionFromId(id);
      setToken(result?.token);
      setConversation([]);
      result?.session_history?.forEach((item) => {
        setConversation((current) => [
          ...current,
          {
            id: current.length + 1,
            content: item.qustion,
            sender: "user",
          },
          {
            id: current.length + 1,
            content: item.answer,
            sender: "bot",
          },
        ]);
      });
    } catch (error) {
      toast.error("Sorry, I am unable to fetch the data");
    }
    // setConversation((current) => [
    //   ...current,
    //   {
    //     id: current.length + 1,
    //     content: result.response,
    //     sender: "bot",
    //   },
    // ]);
  };

  return (
    <div className="xl:pl-72 h-full w-full fixed inset-y-0 flex flex-col">
      <main className="lg:pr-80 relative flex flex-col  justify-between h-full">
        <div className="flex flex-col overflow-hidden">
          <header className="flex items-center xl:justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 mr-2 p-2.5 text-white xl:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div>
              <h1 className="text-base font-semibold leading-7 text-white">
                Hooray Health
              </h1>
              <p className="text-sm font-light leading-7 text-white">
                This chatbot options based on the information provided within
                the supplied PDF.
              </p>
            </div>
          </header>

          {/* Conversation list */}
          <div className="overflow-auto">
            <ul className="divide-y divide-white/5">
              {conversation.map((messages) => (
                <li
                  key={messages.id}
                  className={classnames(
                    messages.sender === "bot"
                      ? "relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
                      : "relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8 bg-white bg-opacity-5"
                  )}
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <div>
                        {messages.sender === "bot" ? (
                          <div>
                            <CpuChipIcon
                              className="h-6 w-6 text-blue-500"
                              aria-hidden="true"
                            />
                          </div>
                        ) : (
                          <UserCircleIcon
                            className="h-5 w-5 text-gray-300"
                            aria-hidden="true"
                          />
                        )}
                      </div>

                      <h2 className="min-w-0 text-base font-semibold leading-6 text-gray-300">
                        {/* {messages.sender === "user" && ( */}
                        <p className="">
                          {/* {messages?.content?.split("\n").map((item, i) => (
                            <span key={i}>
                              {item}
                              <br />
                            </span>
                          ))} */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: messages?.content,
                            }}
                          />
                        </p>

                        {/* )} */}
                        {/* {messages.sender === "bot" && (
													<Typewriter
														onInit={(typewriter) => {
															typewriter.typeString(messages.content).start();
														}}
														options={{ delay: 5, cursor: " " }}
													/>
												)} */}
                        {/* <span className="absolute inset-0" /> */}
                      </h2>
                    </div>
                  </div>
                </li>
              ))}
              {message && (
                <li className="relative flex  space-x-4 px-4 py-4 sm:px-6 lg:px-8">
                  <>
                    <div className="flex items-start gap-x-3">
                      <div>
                        <CpuChipIcon
                          className="h-6 w-6 text-blue-500"
                          aria-hidden="true"
                        />
                      </div>
                      <h2 className="min-w-0 text-base font-semibold leading-6 text-gray-300">
                        <>
                          {/* {messages.content.split("\n").map((item, i) => (
                            <span key={i}>
                              {item}
                              <br />
                            </span>
                          ))} */}
                          <span dangerouslySetInnerHTML={{ __html: message }} />
                        </>
                      </h2>
                    </div>
                    {/* <div className="flex justify-between text-white flex-row-reverse text-xl w-[20px]  rounded-full ml-auto cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                        />
                      </svg>
                    </div> */}
                  </>
                </li>
              )}
              {loading && (
                <li
                  key={9999}
                  className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-8"
                >
                  <div className="min-w-0 flex-auto">
                    <div className="flex items-start gap-x-3">
                      <div>
                        <div>
                          <CpuChipIcon
                            className="h-6 w-6 text-blue-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                        <span className="text-gray-600 italic">
                          Generating response...
                        </span>
                        {/* <span className="absolute inset-0" /> */}
                      </h2>
                    </div>
                  </div>
                  <div
                    className={classnames(
                      "rounded-full flex-none py-1 px-2 text-xs font-medium ring-1 text-white ring-inset"
                    )}
                  >
                    Loading
                  </div>

                  <LoaderIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </li>
              )}
            </ul>
            <div className="float-left clear-both" ref={scrollRef}></div>
          </div>
        </div>
        {conversation.length === 0 && (
          <div className=" flex flex-col items-center overflow-hidden text-gray-50 text-center ">
            <div className=" max-w-2xl">
              <h2 className="text-xl mb-4">
                Welcome to the chatbot{" "}
                {state ? state.userInfo.name : "Developer Mode"}
              </h2>
            </div>
          </div>
        )}
        <div className="p-4">
          <InputBox
            handleSubmit={handleSubmit}
            input={input}
            setInput={setInput}
          />
        </div>
      </main>

      {/* Activity feed */}
      <aside className="bg-black/10 hidden lg:block lg:fixed lg:bottom-0 lg:right-0 lg:top-0 lg:w-80 lg:overflow-y-auto lg:border-l lg:border-white/5">
        <header className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 sm:py-6 lg:px-8">
          <h2 className="text-base font-semibold leading-7 text-white">
            Chat history
          </h2>
          <button
            onClick={() => setToken(Math.random().toString(36).substring(7))}
            className="text-sm font-semibold leading-6 text-indigo-400"
          >
            <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </header>
        <ul role="list" className="divide-y divide-white/5"></ul>
      </aside>
    </div>
  );
}
